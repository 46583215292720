import React, { useEffect, useRef } from "react"
import Cookies from "js-cookie"

export const location = () =>
  typeof window !== `undefined`
    ? window.location
    : { search: "", host: "", src: "", origin: "", pathname: "" }

export function useInterval(callback, delay) {
  const savedCallbackRef = useRef()
  useEffect(() => {
    savedCallbackRef.current = callback
  }, [callback])
  useEffect(() => {
    const handler = (...args) => savedCallbackRef.current(...args)
    if (delay !== null) {
      const intervalId = setInterval(handler, delay)
      return () => clearInterval(intervalId)
    }
  }, [delay])
}

export function computeInnerSize(parent) {
  const left = parent.children[0].getBoundingClientRect().left
  const right = parent.children[
    parent.children.length - 1
  ].getBoundingClientRect().right
  return right - left
}

export function getImageBySize(photo, size) {
  if (!photo) return
  if (!photo.formats && photo.url) return photo.url
  if (!photo.formats) return photo
  return photo.formats[size]?.url || photo.url
}

export function useKeyboardEvent(key, callback) {
  useEffect(() => {
    const handler = event => {
      if (event.key === key) callback()
    }
    document.addEventListener("keydown", handler)
    return () => {
      document.removeEventListener("keydown", handler)
    }
  })
}

export function DisableBodyScroll() {
  return (
    <style dangerouslySetInnerHTML={{ __html: "body { overflow: hidden }" }} />
  )
}

export function localePath({ path, locale }) {
  if (path === "home-en") return "/en"
  if (path === "home") return "/"
  return `/${locale === "fr" ? "" : "en/"}${path.replace(/-en$/, "")}`
}

const params = new URLSearchParams(location().search)

if (params.get("origin")) {
  Cookies.set("wizito-origin", params.get("origin"))
}

export function origin() {
  return params.get("origin") || Cookies.get("wizito-origin")
}

export function path() {
  return `${location().origin}${location().pathname}`
}
